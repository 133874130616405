import { css } from 'lit';

export const errorBannerStyles = css`
    :host {
        display: block;
    }

    .content-wrapper {
  position: relative;
  text-align: left;
  background-color: var(--sbkColorContentBgColorNeutral);
  padding: var(--sbkInput4xl);
  border-radius: 100px 0 100px 0;
  margin: auto;
  overflow: hidden;
}

.logo {
  position: absolute;
  right: 0;
  top: 0;
}

.logo-image {
  width: var(--sbkTeaserSizingMinImageHeightSmallScreenDefault);
  height: var(--sbkTeaserSizingMinImageHeightSmallScreenDefault);
}

.content-text {
  padding-top: 107px;
  text-align: left;
}
`;
